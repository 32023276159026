import React, { useEffect } from "react"
import Helmet from "react-helmet"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"


export default function Terms() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);  return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Terms of Service</title></Helmet>
            <header style={{ background: "#f0f0f0", width: "100%", padding: "20px", textAlign: "center" }}>
                <img src="/img/carbonate-logo.png" alt="Main Logo" style={{ width: "180px" }}/>
            </header>
            <div
                name="termly-embed"
                data-id="8aab25ac-670c-4dda-b1f9-6830dc927ae7"
                data-type="iframe"
            ></div>
            <RawFooter />
        </HomepageLayout>
    );
}
